import { alpha } from "@mui/material";

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#28A745",
  darker: "#08660D",
  contrastText: "#212B36",
};

/* const ERROR = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
}; */

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#C20E30",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const INFO = {
  main: "#FFC222",
};

export const palette = {
  primary: {
    dark: "#3F3F3E",
    main: "#2574BB",
    main100: alpha("#2574BB"),
    main80: alpha("#2574BB", 0.8),
    main60: alpha("#2574BB", 0.6),
    main40: alpha("#2574BB", 0.4),
    main20: alpha("#2574BB", 0.2),
    main10: alpha("#2574BB", 0.1),
    lightContrast: "#FFFFFF",
    darkContrast: "#000000",
    contrastText: "#FFFFFF",
    TableHeader: "#2574BB",
    TableHeader2: "#2574BB",
    text: "#424242",
    selectMenu: "#2574BB",
    borderASummary: "#EEEEEE",
    mainLyrics: '#FFFFFF'
  },
  secondary: {
    dark: "#3F3F3E",
    main: "#E11383",
    main100: alpha("#E11383"),
    main80: alpha("#E11383", 0.8),
    main60: alpha("#E11383", 0.6),
    main40: alpha("#E11383", 0.4),
    main20: alpha("#E11383", 0.2),
    main10: alpha("#E11383", 0.1),
    lightContrast: "#FFFFFF",
    darkContrast: "#3F3F3E",
    contrastText: "#FFFFFF",
    warningAlert: "rgba(255, 184, 0, 0.35)",
  },
  success: { ...SUCCESS },
  error: { ...ERROR },
  info: { ...INFO },
  nabvar: {
    main: "#C20E30",
  },
  table: {
    main: "#243746",
    secondary: "#FFB8B8",
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};
